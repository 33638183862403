import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: !!localStorage.getItem("Signature") ? true : false
  },
  getters: {

  },
  mutations: {
    setIsLogin(state, value) {
      state.isLogin = value
    }
  },
  actions: {},
  modules: {}
})