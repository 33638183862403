<template>
  <div class="mycontainer">
    <a-menu v-model="path" mode="horizontal" @click="changeMenu">
      <a-menu-item key="/home2" v-if="!isLogin">
        <a-icon type="phone" />号卡充值
      </a-menu-item>
      <a-menu-item key="/home" v-if="isLogin">
        <a-icon type="phone" />号卡列表
      </a-menu-item>
      <a-menu-item key="/rechargeLog" v-if="isLogin">
        <a-icon type="bar-chart" />充值记录
      </a-menu-item>
    </a-menu>
    <div class="loginout" @click="loginout" v-if="isLogin">退出登录</div>
    <div class="loginout gologin" @click="gologin" v-else>点击登录</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    path() {
      return [this.$route.path];
    },
    isLogin() {
      return !!localStorage.getItem("Signature");
    },
  },
  methods: {
    changeMenu(e) {
      console.log(e);
      this.$router.push(e.key);
    },
    loginout() {
      this.$confirm({
        title: "提示",
        content: "是否退出当前帐号",
        okText: "确认退出",
        cancelText: "取消",
        onOk: () => {
          localStorage.removeItem("Signature");
          localStorage.removeItem("UId");
          this.$router.replace("/login");
        },
      });
    },
    gologin() {
      this.$router.replace("/login");
    },
  },
};
</script>

<style scoped>
.mycontainer {
  padding: 0 10px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}
.loginout {
  color: #1890ff;
}
</style>