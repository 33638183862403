<template>
  <div class="HomeView">
    <MyMenuVue />
    <div class="mycontainer Container">
      <!-- <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="号卡充值"
        sub-title="号卡开卡、续费操作"
      >
        <template slot="extra">
          <a-button
            key="1"
            type="primary"
            :disabled="checkedList.length == 0"
            @click="recharge"
          >
            批量充值
          </a-button>
        </template>
        <div class="main">
          <a-descriptions size="small" :column="2">
            <a-descriptions-item label="号卡总数">
              {{ total }}张
            </a-descriptions-item></a-descriptions
          >
        </div>
      </a-page-header> -->
      <div style="height: 10px"></div>

      <div class="content">
        <a-form layout="inline" :form="dataForm">
          <a-form-item label="号码搜索：">
            <a-input
              v-model="dataForm.Phone"
              placeholder="手机号码搜索"
              style="width: 200px"
            ></a-input>
          </a-form-item>
          <a-form-item label="套餐充值：">
            <a-radio-group v-model="dataForm.IsRechargePhonePlan">
              <a-radio :value="''"> 全部 </a-radio>
              <a-radio :value="true"> 已充值 </a-radio>
              <a-radio :value="false"> 未充值 </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="勾选">
            <a-checkbox
              :indeterminate="indeterminate"
              @change="changeAll"
              :checked="isAllCheck"
            >
              号卡全部勾选
            </a-checkbox>
          </a-form-item>
          <a-form-item>
            <a-button type="" @click="getDataList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-button
              key="1"
              type="primary"
              :disabled="checkedList.length == 0"
              @click="recharge"
            >
              批量充值
            </a-button>
          </a-form-item>
        </a-form>
        <div style="height: 10px"></div>

        <a-spin :spinning="spinning">
          <a-row :gutter="[20, 20]">
            <!-- <a-checkbox-group
              v-model="checkedList"
              name="checkboxgroup"
              style="width: 100%"
              @change="onChange"
            > -->
            <a-col v-for="(v, i) in List" :key="i" :xs="24" :sm="24" :xl="8">
              <a-card hoverable>
                <template slot="actions">
                  <!-- <div>单个充值</div> -->
                  <a-checkbox
                    :value="v.Tel"
                    :label="v.Tel"
                    :checked="isChecked(v.Tel)"
                    @change="onChange"
                    >勾选充值</a-checkbox
                  >
                  <!-- <a-icon key="setting" type="setting" /> -->
                  <!-- <a-icon key="edit" type="edit" />
                  <a-icon key="ellipsis" type="ellipsis" /> -->
                </template>
                <a-card-meta :title="v.Tel">
                  <div slot="description">
                    <div>套餐名称： {{ v.PhonePlanName }}</div>
                    <!-- <div style="color: #1890ff; font-weight: bold">
                      余额：{{ v.Amount }}元
                    </div> -->
                    <div style="color: #1890ff; font-weight: bold">
                      本月已使用分钟数：{{ v.UsedCallTime || 0 }}分钟
                    </div>
                    <div style="color: #1890ff; font-weight: bold">
                      本月已使用流量：{{ v.UsedData || 0 }}MB
                    </div>
                    <div style="color: #1890ff; font-weight: bold">
                      本月已使用短信：{{ v.UsedMsg || 0 }}条
                    </div>
                    <div style="color: #1890ff; font-weight: bold">
                      状态：{{ v.StopOpenStatus }}
                    </div>
                  </div>
                </a-card-meta>
              </a-card>
            </a-col>
            <!-- </a-checkbox-group> -->
          </a-row>
          <a-empty v-if="List.length == 0" style="margin-top: 40px" />
        </a-spin>

        <div style="height: 30px"></div>
        <a-pagination
          v-model="PageIndex"
          :total="total"
          show-less-items
          show-size-changer
          :page-size="PageSize"
          :page-size-options="['10', '20', '50', '200']"
          @change="changeIndex"
          @showSizeChange="onShowSizeChange"
        />
        <div style="height: 30px"></div>
        <!-- 
          <a-button type="primary"> 充值 </a-button> -->
      </div>
    </div>
    <Re ref="re" @ok="getDataList" :PhoneInfo="PhoneInfo"></Re>
  </div>
</template>

<script>
import { service } from "@/utils/request";
import MyMenuVue from "@/components/MyMenu.vue";
import Re from "./module/re.vue";
export default {
  components: { MyMenuVue, Re },
  data() {
    return {
      spinning: false,
      checkedList: [],
      dataForm: {
        Phone: "",
        IsRechargePhonePlan: "",
      },
      List: [],
      PageIndex: 1,
      PageSize: 10,
      total: 0,
      wechatConfig: {},
      indeterminate: false,
      isAllCheck: false,
    };
  },
  computed: {
    PhoneInfo() {
      let Amount = 0;
      let ActualPhonePlanSellingPrice = 0;
      let PhonePlanSellingPrice = 0;
      let VoiceSellingPrice = 0;
      this.List.forEach((item) => {
        Amount += parseFloat(item.Amount) || 0;
        ActualPhonePlanSellingPrice += item.ActualPhonePlanSellingPrice;
        PhonePlanSellingPrice += item.PhonePlanSellingPrice;
        VoiceSellingPrice += item.VoiceSellingPrice;
      });
      console.log(Amount)
      return {
        Amount,
        ActualPhonePlanSellingPrice,
        PhonePlanSellingPrice,
        VoiceSellingPrice,
      };
    },
  },
  created() {
    this.getList();
    const code = this.getParameterByName("code");
    // const state = this.getParameterByName("state");
    if (code) {
      console.log(code);
      this.codeBack(code);
    }
  },
  // mounted() {
  //   this.$refs.re.init([1808213802]);
  //   this.$refs.re.currentTab = 1;
  //   this.$refs.setAmount(1);
  // },
  methods: {
    changeAll(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.List.map((v) => v.Tel) : [],
        indeterminate: false,
        isAllCheck: e.target.checked,
      });
      // console.log(e);
      // if (e.target.checked) {
      //   this.List.forEach((v) => {
      //     let index = this.checkedList.findIndex((k) => v.Tel == v);
      //     if (index == -1) {
      //       this.checkedList.push(v.Tel);
      //     }
      //   });
      // } else {
      //   this.checkedList = [];
      // }
    },
    onChange(e) {
      if (e.target.checked) {
        this.checkedList.push(e.target.value);
      } else {
        const index = this.checkedList.findIndex((v) => v == e.target.value);
        if (index > -1) {
          this.checkedList.splice(index, 1);
        }
      }
      this.indeterminate =
        !!this.checkedList.length && this.checkedList.length < this.List.length;
      this.isAllCheck = this.checkedList.length === this.List.length;
    },
    isChecked(Tel) {
      const index = this.checkedList.findIndex((v) => v == Tel);
      return index > -1;
    },
    getDataList() {
      this.checkedList = [];
      this.PageIndex = 1;
      this.getList();
    },
    getList() {
      this.spinning = true;
      service({
        url: "/crm2/api/PhoneRecharge/GetCallers",
        method: "post",
        data: {
          PageIndex: this.PageIndex,
          PageSize: this.PageSize,
          ...this.dataForm,
        },
      })
        .then(({ response: res }) => {
          this.checkedList = [];
          this.total = res.dataCount;
          this.List = res.data;
          // this.List = [
          //   ...res.data,
          //   ...res.data,
          //   ...res.data,
          //   ...res.data,
          //   ...res.data,
          //   ...res.data,
          //   ...res.data,
          //   ...res.data,
          //   ...res.data,
          // ];
          // this.total = 100;
        })
        .finally(() => (this.spinning = false));
    },
    recharge() {
      return this.$refs.re.init(this.checkedList);
    },
    changeIndex(e) {
      this.PageIndex = e;
      this.getList();
    },
    onShowSizeChange(e, e2) {
      this.PageSize = e2;
      this.getDataList();
    },
  },
};
</script>

<style scoped lang="scss">
.Container {
  padding: 20px;
}
.price {
  color: red;
  font-weight: bold;
}
.mycontainer {
  padding: 0 10px;
  max-width: 1200px;
  margin: 0 auto;
}
.cardList {
  color: #222;
  font-size: 16px;
  line-height: 36px;
}
</style>