import axios from 'axios'
import Message from 'ant-design-vue/lib/message'
import router from '@/router';
// const baseURL = "http://192.168.1.119:9091";
// const baseURL = "https://api.situ123.com";
const baseURL = "http://apipre.situ123.com";

const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  timeout: 10000 // request timeout
})

service.interceptors.request.use(function (config) {
  // config.headers['app'] = "Web2"
  const Signature = localStorage.getItem('Signature') || ''
  const UId = localStorage.getItem('UId') || ''
  config.headers['Signature'] = Signature
  config.headers['Uid'] = UId
  return config
}, function (error) {
  return Promise.reject(error)
});
service.interceptors.response.use(function (response) {
  if (response.data && response.data.Code == 11) {
    Message.error('登录已过期，请登录');
    router.replace("/login")
    return Promise.reject(response.data);
  }
  if (response.status != 200 || !response.data.success) {
    let message = response.data.msg ? response.data.msg : "服务器开小差了，请稍后重试";
    Message.error(message);
    return Promise.reject(response.data);
  }
  return response.data;
}, (error) => {
  console.log(error);
  Message.error(error.response.data.msg || '服务器开小差了～');
  return Promise.reject(error);
});


export {
  service,
  baseURL
}