<template>
  <div class="HomeView">
    <MyMenuVue />
    <div class="mycontainer">
      <a-result
        title="请输入需要查询的号码"
        sub-title="输入需要充值或查询的手机号，然后点击【立即查询】"
      >
        <template #icon>
          <a-icon
            :type="JSON.stringify(PhoneInfo) == '{}' ? 'smile' : 'check-circle'"
            theme="twoTone"
          />
        </template>
        <template #extra>
          <a-input
            placeholder="请输入手机号"
            v-model="Phone"
            type="number"
            style="max-width: 400px; margin-bottom: 10px"
            @change="changePhone"
          />
          <div>
            <a-button
              key="console"
              type="primary"
              @click="searchPhone"
              :loading="searchloading"
            >
              立即查询
            </a-button>
          </div>
        </template>
      </a-result>
      <div v-if="JSON.stringify(PhoneInfo) != '{}'">
        <div class="desc">
          <p style="font-size: 16px">
            <strong>号卡查询结果</strong>
          </p>
          <a-row type="flex" align="middle">
            <a-col flex="1 1 200px">
              <p>
                <a-icon :style="{ color: 'green' }" type="check-circle" />
                手机号码：
                <a>{{ PhoneInfo.Tel }} </a>
                <!-- &gt; -->
              </p>
              <p>
                <a-icon :style="{ color: 'green' }" type="check-circle" />
                套餐名称：
                <a>{{ PhoneInfo.PhonePlanName }}</a>
              </p>
              <!-- <p>
                <a-icon :style="{ color: 'green' }" type="check-circle" />
                余额：
                <a>{{ PhoneInfo.Amount }}</a>
              </p> -->
              <p>
                <a-icon :style="{ color: 'green' }" type="check-circle" />
                本月已使用分钟数：
                <a>{{ PhoneInfo.UsedCallTime || 0 }}分钟</a>
              </p>
              <p>
                <a-icon :style="{ color: 'green' }" type="check-circle" />
                本月已使用流量：
                <a>{{ PhoneInfo.UsedData || 0 }}MB</a>
              </p>
              <p>
                <a-icon :style="{ color: 'green' }" type="check-circle" />
                本月已使用短信：
                <a>{{ PhoneInfo.UsedMsg || 0 }}条</a>
              </p>
              <p>
                <a-icon :style="{ color: 'green' }" type="check-circle" />
                状态：
                <a>{{ PhoneInfo.StopOpenStatus }}</a>
              </p>
            </a-col>
            <a-col flex="0 1 100px">
              <a-button key="console" type="primary" @click="regPhone">
                点击充值
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="desc" v-for="v in List" :key="v.Id">
          <div>充值号码：{{ v.Caller }}</div>
          <div style="color: #1890ff; font-weight: bold">
            支付金额：{{ v.SellingAmount }}元
          </div>
          <div>
            支付状态：<a-tag :color="v.IsSuccess ? '#1890ff' : '#ccc'">{{
              v.IsSuccess ? "支付成功" : "未支付"
            }}</a-tag>
          </div>
          <div>创建时间{{ v.CreateTime }}</div>
        </div>
        <div class="descpage">
          <a-pagination
            v-model="PageIndex"
            :total="total"
            :defaultPageSize="PageSize"
            show-less-items
            @change="changeIndex"
          />
        </div>
      </div>
    </div>
    <Re ref="re" @ok="getLogList" :PhoneInfo="PhoneInfo"></Re>
  </div>
</template>

<script>
import { service } from "@/utils/request";
import MyMenuVue from "@/components/MyMenu.vue";
import Re from "./module/re.vue";
import wxpaymixin from "@/utils/wxpaymixin";
export default {
  mixins: [wxpaymixin],
  data() {
    return {
      Phone: "",
      PhoneInfo: {},
      spinning: false,
      PageIndex: 1,
      PageSize: 10,
      total: 0,
      List: [],
      searchloading: false,
    };
  },
  components: { MyMenuVue, Re },
  created() {
    const code = this.getParameterByName("code");
    // const state = this.getParameterByName("state");
    if (code) {
      console.log(code);
      this.codeBack(code);
    }
  },
  mounted() {
    const tel = this.$route.query.tel;
    if (tel) {
      this.Phone = tel;
      this.searchPhone();
    }
  },
  methods: {
    //查询号卡
    searchPhone() {
      if (!this.Phone) {
        this.$message.error("请输入查询手机号");
      }
      this.searchloading = true;
      service({
        url: "/crm2/api/PhoneRechargeAnonymous/GetCaller?Phone=" + this.Phone,
        method: "get",
      })
        .then(({ response: res }) => {
          console.log(res);
          if (res) {
            this.PhoneInfo = res;
            this.getLogList();
          } else {
            this.PhoneInfo = {};
            this.$message.error("未查询到此号码");
          }
        })
        .finally(() => {
          this.searchloading = false;
        });
    },
    getLogList() {
      this.spinning = true;
      service({
        url: "/crm2/api/PhoneRechargeAnonymous/GetRechargeLogs",
        method: "post",
        data: {
          PageIndex: this.PageIndex,
          PageSize: this.PageSize,
          Phone: this.Phone,
        },
      })
        .then(({ response: res }) => {
          this.total = res.dataCount;
          this.List = res.data;
        })
        .finally(() => (this.spinning = false));
    },
    changeIndex(e) {
      this.PageIndex = e;
      this.getLogList();
    },
    changePhone(e) {
      if (e.target.value === "") {
        this.PhoneInfo = {};
      }
    },
    //充值
    regPhone() {
      this.$refs.re.init([this.PhoneInfo.Tel], this.PhoneInfo.CompanyId);
    },
    initWechatJsSDK() {
      var that = this;
      function onBridgeReady() {
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: that.wechatConfig.appId, //公众号ID，由商户传入
            timeStamp: that.wechatConfig.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: that.wechatConfig.nonceStr, //随机串
            package: that.wechatConfig.package,
            signType: that.wechatConfig.signType, //微信签名方式：
            paySign: that.wechatConfig.paySign,
          },
          function (res) {
            console.log(res);
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              that.$refs.re.currentTab = 2;
            }
          }
        );
      }
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    },
  },
};
</script>

<style scoped lang="less">
.mycontainer {
  padding: 0 10px;
  max-width: 1200px;
  margin: 0 auto;
}
.search {
  padding: 40px 0;
  max-width: 400px;
  margin: 0 auto;
}
.searchName {
  text-align: center;
  margin-bottom: 10px;
  font-size: 22px;
}

.desc {
  background: #fafafa;
  padding: 30px;
  max-width: 876px;
  margin: 0 auto 20px;
}
.descpage {
  max-width: 876px;
  margin: 0 auto 20px;
  text-align: center;
}
</style>