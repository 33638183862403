import {
    mapState
} from "vuex";
import {
    service
} from "./request";
import {
    computedLink
} from '../utils/index'
export default {
    computed: {
        ...mapState(["isLogin"]),
    },
    methods: {
        getParameterByName(name, url = window.location.href) {
            name = name.replace(/[\[\]]/g, "\\$&");
            const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
            const results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
        //公众号支付回调
        codeBack(code) {
            const canshuEn = localStorage.getItem("canshuEn")
            const queryString = decodeURIComponent(canshuEn);
            const params = new URLSearchParams(queryString);
            const obj = {};
            for (const [key, value] of params.entries()) {
                obj[key] = value == undefined ? null : value;
            }
            console.log(obj);
            const checkedList = obj.checkedList.split("p");
            const Amount = obj.Amount;
            const RechargeType = obj.RechargeType;
            const CompanyId = obj.CompanyId;
            const PlanTotalMinutes = obj.PlanTotalMinutes;
            console.log(checkedList);
            console.log(Amount);
            console.log(RechargeType);
            console.log(CompanyId);
            console.log(PlanTotalMinutes);
            if (CompanyId) {
                this.Phone = checkedList[0] || ''
                this.searchPhone()
            }
            service({
                url: this.isLogin ? "/crm2/api/PhoneRecharge/PubPay" : "/crm2/api/PhoneRechargeAnonymous/PubPay",
                method: "post",
                data: {
                    Phones: checkedList,
                    Amount: !!Amount ? Amount : undefined,
                    code: code,
                    RechargeType: RechargeType,
                    CompanyId: CompanyId,
                    PlanTotalMinutes: !!PlanTotalMinutes ? PlanTotalMinutes : null,
                    Link: computedLink(checkedList[0]),
                },
            }).then((res) => {
                this.wechatConfig = res.response;
                this.initWechatJsSDK();
                this.$refs.re.init(checkedList, CompanyId);
                this.$refs.re.currentTab = 1;
                this.$refs.re.setAmount(Amount || "");
                if (RechargeType) {
                    this.$refs.re.setRechargeType(RechargeType);
                }
                if (PlanTotalMinutes) {
                    this.$refs.re.setPlanTotalMinutes(PlanTotalMinutes);
                }
            });
        },
    }
}