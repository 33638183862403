import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {
  Button,
  message,
  Form,
  FormModel,
  Input,
  Checkbox,
  Icon,
  Tabs,
  Card,
  Row,
  Col,
  Menu,
  Mentions,
  Pagination,
  PageHeader,
  Descriptions,
  Modal,
  Steps,
  Radio,
  Tag,
  LocaleProvider,
  Result,
  Spin,
  Empty,
  DatePicker,
  Tooltip,
  Divider
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Button)
Vue.use(message)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Icon)
Vue.use(Tabs)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(Mentions)
Vue.use(Pagination)
Vue.use(PageHeader)
Vue.use(Descriptions)
Vue.use(Modal)
Vue.use(Steps)
Vue.use(Radio)
Vue.use(Tag)
Vue.use(LocaleProvider)
Vue.use(Result)
Vue.use(Spin)
Vue.use(Empty)
Vue.use(DatePicker)
Vue.use(Tooltip)
Vue.use(Divider)

Vue.config.productionTip = false
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$message = message;

// import {
//   BootstrapVue,
//   IconsPlugin
// } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// // import 'bootstrap-vue/dist/bootstrap-vue.css'
// // Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// // Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')