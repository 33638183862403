<template>
  <a-modal
    v-model="visible"
    title="充值"
    @ok="handleOk"
    width="750px"
    :confirmLoading="confirmLoading"
    :maskClosable="false"
    :keyboard="false"
    :okText="currentTab == 1 ? '我已支付完成' : '确认'"
    cancelText="关闭"
    @cancel="cancelClose"
  >
    <a-steps class="steps" :current="currentTab">
      <a-step title="确认订单" />
      <a-step title="支付" />
      <a-step title="完成" />
    </a-steps>

    <div style="max-width: 500px; margin: 40px auto 0">
      <!-- <div>
        {{ rechargetype }}
      </div> -->
      <a-form
        v-show="currentTab == 0"
        :form="form"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-form-item label="充值类型">
          <a-radio-group
            v-decorator="['rechargetype', { initialValue: 'PhonePlan' }]"
            @change="
              (e) => {
                rechargetype = e.target.value;
              }
            "
          >
            <a-radio value="PhonePlan">
              套餐充值
              <a-tooltip placement="top" :title="'充值本月的套餐'">
                <a-icon type="question-circle" />
              </a-tooltip>
            </a-radio>
            <!-- <a-radio value="Balance">
              套餐外充值
              <a-tooltip
                placement="top"
                title="套餐外充值，用于抵扣套餐外语音、流量、短信"
              >
                <a-icon type="question-circle" />
              </a-tooltip>
            </a-radio> -->
            <a-radio value="AddOnPhonePlan">
              套餐外分钟数充值
              <!-- <a-tooltip placement="top" title="套餐外分钟数充值">
                <a-icon type="question-circle" />
              </a-tooltip> -->
            </a-radio>
            <a-radio value="PrestorePhonePlan">
              预存套餐
              <a-tooltip
                placement="top"
                :title="
                  '预存' +
                  (new Date().getMonth() + 2 > 12
                    ? 1
                    : new Date().getMonth() + 2) +
                  '月的套餐'
                "
              >
                <a-icon type="question-circle" />
              </a-tooltip>
            </a-radio>
            <a-radio value="Reactivation" v-if="PhoneInfo.Amount < 0">
              缴清复机
              <a-tooltip placement="top" title="一次性缴清已欠费金额并复机">
                <a-icon type="question-circle" />
              </a-tooltip>
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="充值号码">
          <a-tag v-for="v in checkedList" :key="v" size="large">{{ v }}</a-tag>
        </a-form-item>
        <a-form-item label="套餐支付金额" v-if="rechargetype == 'PhonePlan'">
          {{ PhoneInfo.ActualPhonePlanSellingPrice }}元
        </a-form-item>
        <a-form-item
          label="预存套餐支付金额"
          v-if="rechargetype == 'PrestorePhonePlan'"
        >
          {{ PhoneInfo.PhonePlanSellingPrice }}元
        </a-form-item>
        <a-form-item
          label="套外分钟数支付金额"
          v-if="rechargetype == 'AddOnPhonePlan'"
        >
          {{
            form.getFieldValue("PlanTotalMinutes")
              ? PhoneInfo.VoiceSellingPrice *
                form.getFieldValue("PlanTotalMinutes")
              : "--"
          }}元
        </a-form-item>
        <a-form-item
          label="缴清复机支付金额"
          v-if="rechargetype == 'Reactivation'"
        >
          {{ Math.abs(PhoneInfo.Amount) }}元
        </a-form-item>
        <!-- <a-form-item
          label="小额充值金额"
          v-if="form.getFieldValue('rechargetype') == 'Balance'"
        >
          <a-input
            prefix="￥"
            placeholder="请输入"
            type="number"
            v-decorator="[
              'Amount',
              {
                initialValue: '',
                rules: [
                  {
                    validator: validateToNextPassword,
                  },
                ],
              },
            ]"
          />
        </a-form-item> -->
        <a-form-item
          label="套外类型"
          v-if="form.getFieldValue('rechargetype') == 'AddOnPhonePlan'"
        >
          <a-radio-group
            v-decorator="[
              'PlanTotalMinutes',
              {
                initialValue: '',
                rules: [{ required: true, message: '请选择充值分钟数' }],
              },
            ]"
          >
            <a-radio :value="v.value" v-for="(v, i) in yyarr" :key="i">
              {{ v.label }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="支付方式">
          <a-radio-group
            v-decorator="[
              'pay',
              {
                initialValue: '',
                rules: [{ required: true, message: '请选择付款方式' }],
              },
            ]"
          >
            <a-radio :value="1" v-if="!isMobile"> 微信二维码付款 </a-radio>
            <a-radio :value="2" v-if="isWeixin"> 微信付款 </a-radio>
            <a-radio :value="3" v-if="!isMobile"> 支付宝二维码付款 </a-radio>
            <a-radio :value="4" v-if="isMobile"> 支付宝付款充值 </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <div v-if="currentTab == 1">
        <div class="qrcode" v-if="form.getFieldValue('pay') == 1">
          <img :src="qrcode" alt="" />
          <!-- 这里的src的值就是生成的二维码的地址 -->
          <p style="color: #1890ff">支付金额：{{ priceQrNumber }}</p>
          <p>请使用微信扫码支付</p>
        </div>
        <div class="qrcode" v-else-if="form.getFieldValue('pay') == 2">
          <p>微信支付中···</p>
        </div>
        <div class="qrcode" v-else>支付中···</div>
      </div>
      <div v-if="currentTab == 2">
        <a-result
          title="操作成功"
          :is-success="true"
          sub-title="预计两小时内到账"
        >
          <div class="information">
            <a-row>
              <a-col :sm="8" :xs="24">充值号卡：</a-col>
              <a-col :sm="16" :xs="24"
                ><a-tag v-for="v in checkedList" :key="v" size="large">{{
                  v
                }}</a-tag></a-col
              >
            </a-row>
            <!-- <a-row>
              <a-col
                >充值类型：{{
                  form.getFieldValue("rechargetype") == "PhonePlan"
                    ? "套餐充值"
                    : form.getFieldValue("rechargetype") == "Balance"
                    ? "小额充值"
                    : form.getFieldValue("rechargetype") == "PrestorePhonePlan"
                    ? "预存套餐"
                    : ""
                }}</a-col
              >
            </a-row> -->
          </div>
          <template #extra>
            <!-- <a-button type="primary" @click="finish">再转一笔</a-button> -->
            <!-- <a-button style="margin-left: 8px">关闭</a-button> -->
          </template>
        </a-result>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { service } from "@/utils/request";
import QRCode from "qrcode";
import { mapState } from "vuex";
import { computedLink } from "@/utils/index";
export default {
  props: {
    PhoneInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    rechargetype() {
      return this.form.getFieldValue("rechargetype");
    },
  },
  data() {
    return {
      rechargetype: "PhonePlan",
      visible: false,
      currentTab: 0,
      priceQrNumber: "",
      labelCol: { lg: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 17 }, sm: { span: 17 } },
      form: this.$form.createForm(this),
      confirmLoading: false,
      checkedList: [],
      qrcode: "",
      aliform: null,
      CompanyId: "",
      yyarr: [
        {
          label: "200分钟",
          value: 200,
        },
        {
          label: "500分钟",
          value: 500,
        },
        {
          label: "1000分钟",
          value: 1000,
        },
        {
          label: "2000分钟",
          value: 2000,
        },
      ],
    };
  },
  computed: {
    isWeixin() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    isMobile() {
      const regexMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return regexMobile.test(navigator.userAgent);
    },
    ...mapState(["isLogin"]),
  },
  methods: {
    init(checkedList, CompanyId) {
      this.checkedList = checkedList;
      if (CompanyId) {
        this.CompanyId = CompanyId;
      }
      this.visible = true;
    },
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    validateToNextPassword(rule, value, callback) {
      if (value == "" || value == undefined || value == null) {
        callback("请输入有效的金额");
      } else if (!this.isPositiveInteger(value)) {
        callback("请输入大于0的整数金额");
      } else {
        callback();
      }
    },
    isPositiveInteger(str) {
      // 将字符串转换为整数
      const num = parseInt(str, 10);
      // 检查字符串是否为有效整数且大于0
      return !isNaN(num) && num > 0 && num.toString() === str;
    },
    setAmount(Amount) {
      this.form.setFieldsValue("Amount", Amount);
    },
    setRechargeType(RechargeType) {
      this.form.setFieldsValue("rechargetype", RechargeType);
    },
    setPlanTotalMinutes(PlanTotalMinutes) {
      this.form.setFieldsValue("PlanTotalMinutes", PlanTotalMinutes);
    },
    handleOk() {
      if (this.currentTab == 0) {
        const {
          form: { validateFields },
        } = this;
        // 先校验，通过表单校验后，才进入下一步
        validateFields(async (err, values) => {
          if (!err) {
            this.confirmLoading = true;
            if (values.pay == 1) {
              //微信二维码充值
              service({
                method: "post",
                url: this.isLogin
                  ? "/crm2/api/PhoneRecharge/WechatQrCodePay"
                  : "/crm2/api/PhoneRechargeAnonymous/WechatQrCodePay",
                data: {
                  Phones: this.checkedList,
                  Amount: !!values.Amount ? values.Amount : undefined,
                  RechargeType: this.form.getFieldValue("rechargetype"),
                  CompanyId: this.CompanyId,
                  PlanTotalMinutes: this.form.getFieldValue("PlanTotalMinutes"),
                  Link: computedLink(this.PhoneInfo.Tel),
                },
              })
                .then((res) => {
                  this.priceQrNumber = res.msg;
                  this.currentTab = 1;
                  QRCode.toDataURL(res.response, {
                    //这个this.url就是你扫码后要跳转的地址
                    version: 7, //版本号
                    errorCorrectionLevel: "Q", //容错率,(建议选较低)更高的级别可以识别更模糊的二维码，但会降低二维码的容量
                    width: 250, //设置二维码图片大小
                    height: 250,
                  })
                    .then((url) => {
                      this.qrcode = url; //赋值给变量。用在img的src属性上
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                })
                .finally(() => (this.confirmLoading = false));
            }
            if (values.pay == 2) {
              const res = await service({
                method: "get",
                url: this.isLogin
                  ? "/crm2/api/PhoneRecharge/GetAppId"
                  : `/crm2/api/PhoneRechargeAnonymous/GetAppId?CompanyId=${this.CompanyId}`,
              });
              const appid = res.response;
              const redirect_uri = encodeURIComponent(
                this.isLogin
                  ? `${window.location.origin}/h5/#/home`
                  : `${window.location.origin}/h5/#/home2`
              );
              const Amount = this.form.getFieldValue("Amount");
              const RechargeType = this.form.getFieldValue("rechargetype");
              const PlanTotalMinutes =
                this.form.getFieldValue("PlanTotalMinutes") == undefined
                  ? ""
                  : this.form.getFieldValue("PlanTotalMinutes");
              //微信公众号支付
              const canshuEn = encodeURIComponent(
                `checkedList=${this.checkedList.join("p")}&Amount=${
                  !!Amount ? Amount : ""
                }&RechargeType=${RechargeType}&PlanTotalMinutes=${PlanTotalMinutes}` +
                  (!this.isLogin ? `&CompanyId=${this.CompanyId}` : "")
              );
              localStorage.setItem("canshuEn", canshuEn);
              window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&scope=snsapi_base&response_type=code&state=}`;
            }
            if (values.pay == 3 || values.pay == 4) {
              //支付宝二维码付款
              service({
                method: "post",
                url:
                  values.pay == 3
                    ? this.isLogin
                      ? "/crm2/api/PhoneRecharge/AliQrCodePay"
                      : "/crm2/api/PhoneRechargeAnonymous/AliQrCodePay"
                    : this.isLogin
                    ? "/crm2/api/PhoneRecharge/AliWapPay"
                    : "/crm2/api/PhoneRechargeAnonymous/AliWapPay",
                data: {
                  Phones: this.checkedList,
                  Amount: !!values.Amount ? values.Amount : undefined,
                  RechargeType: this.form.getFieldValue("rechargetype"),
                  CompanyId: this.CompanyId,
                  PlanTotalMinutes: this.form.getFieldValue("PlanTotalMinutes"),
                  Link: computedLink(this.PhoneInfo.Tel),
                },
              })
                .then(({ response: res }) => {
                  this.currentTab = 1;
                  const div = document.createElement("divform");
                  div.innerHTML = res;
                  document.body.appendChild(div);
                  document.body.appendChild(div);
                  // document.forms["submit"].setAttribute("target", "_blank");
                  document.forms["submit"].submit();
                })
                .finally(() => (this.confirmLoading = false));
            }
          }
        });
      }
      if (this.currentTab == 1) {
        this.$confirm({
          title: "支付提示",
          content: "是否已经完成本次支付，正常支付完成后将充值到账",
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            this.currentTab = 2;
          },
        });
      }
      if (this.currentTab == 2) {
        this.cancelClose();
      }
    },
    cancelClose() {
      this.visible = false;
      this.currentTab = 0;
      this.rechargetype = "PhonePlan";
      this.form.resetFields();
      this.$emit("ok");
      if (this.isLogin) {
        window.location.href = `http://${window.location.host}/h5/#/home`;
      } else {
        window.location.href = `http://${window.location.host}/h5/#/home2`;
      }
    },
  },
};
</script>

<style scoped>
.steps {
  max-width: 500px;
  margin: 16px auto;
}
.qrcode {
  text-align: center;
}
</style>